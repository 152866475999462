.card {
  transition: all 0.3s ease-in-out;
}

.card.meta-card {
  position: sticky;
  top: 100px;
}

.card.scale:hover {
  transform: scale(1.02);
}
