.hero {
  background-color: #0841b0;
  padding-bottom: 1.5rem;
}

.hero .title {
  color: #ffffff !important;
  font-family: Decour, source-sans-pro, Arial, sans-serif !important;
  font-weight: 400 !important;
}

.hero .subtitle {
  padding-top: 8px;
  color: #ffffff !important;
  font-size: 20px;
  font-weight: 600 !important;
  letter-spacing: 0.5px !important;
}

.hero .content {
  color: #ffffff !important;
  font-size: 1.1rem;
}

.hero.is-light {
  background-color: #f4f6f8 !important;
  padding-top: 2rem;
}

.hero.is-light .title {
  color: #1f1f1f !important;
}

.hero.is-light .subtitle {
  color: #1f1f1f !important;
}

.hero.is-light .content {
  color: #1f1f1f !important;
}
