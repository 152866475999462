.notification {
  color: #ffffff !important;
  background-color: #00ae41 !important;
}

.notification.is-info {
  background-color: #0841b0 !important;
}

.notification.is-warning {
  background: #f9bf3b !important;
}

.notification.is-danger {
  background-color: #ef4836 !important;
}
