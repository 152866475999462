button {
  transition: all 0.2s ease-in-out;
  outline: none !important;
}

.button {
  color: #ffffff !important;
  background-color: #00ae41 !important;
  border: none !important;
  padding: 0 40px 0 40px !important;
  height: 46px !important;
  border-radius: 0px !important;
  font-size: 14px !important;
}

.button:hover {
  background-color: #006024 !important;
  border: none !important;
}

.button.is-extra-large {
  padding: 0 40px 0 40px;
  height: 70px;
  border-radius: 0px;
  font-size: 20px;
}

.button.is-large {
  padding: 0 40px 0 40px;
  height: 56px;
  border-radius: 0px;
  font-size: 16px;
}

.button.is-standard {
  padding: 0 40px 0 40px;
  height: 46px;
  border-radius: 0px;
  font-size: 14px;
}

.button.is-small {
  padding: 0 40px 0 40px;
  height: 38px;
  border-radius: 0px;
  font-size: 13px;
}

.button.is-extra-small {
  padding: 0 40px 0 40px;
  height: 30px;
  border-radius: 0px;
  font-size: 12px;
}

.button.is-outlined {
  color: #00ae41 !important;
  background-color: transparent !important;
  border: 2px solid #00ae41 !important;
}
