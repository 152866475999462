.la-atom,
.la-atom > div {
  margin: auto;
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.la-atom {
  display: block;
  font-size: 0;
  color: #00ae41;
}
.la-atom.la-dark {
  color: #0841b0;
}
.la-atom > div {
  display: inline-block;
  float: none;
  background-color: currentColor;
  border: 0 solid currentColor;
}
.la-atom {
  position: fixed;
  top: -100%;
  right: -100%;
  left: -100%;
  bottom: -100%;
  margin: auto;
  z-index: 0;
}
.la-atom > div:nth-child(1) {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  width: 60%;
  height: 60%;
  background: #0841b0;
  border-radius: 100%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.la-atom > div:not(:nth-child(1)) {
  position: absolute;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  background: none;
  -webkit-animation: ball-atom-zindex 1.5s 0s infinite steps(2, end);
  -moz-animation: ball-atom-zindex 1.5s 0s infinite steps(2, end);
  -o-animation: ball-atom-zindex 1.5s 0s infinite steps(2, end);
  animation: ball-atom-zindex 1.5s 0s infinite steps(2, end);
}
.la-atom > div:not(:nth-child(1)):before {
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  margin-top: -5px;
  margin-left: -5px;
  content: "";
  background: currentColor;
  border-radius: 50%;
  -webkit-animation: ball-atom-position 1.5s 0s infinite ease,
    ball-atom-size 1.5s 0s infinite ease;
  -moz-animation: ball-atom-position 1.5s 0s infinite ease,
    ball-atom-size 1.5s 0s infinite ease;
  -o-animation: ball-atom-position 1.5s 0s infinite ease,
    ball-atom-size 1.5s 0s infinite ease;
  animation: ball-atom-position 1.5s 0s infinite ease,
    ball-atom-size 1.5s 0s infinite ease;
}
.la-atom > div:nth-child(2) {
  -webkit-animation-delay: 0.75s;
  -moz-animation-delay: 0.75s;
  -o-animation-delay: 0.75s;
  animation-delay: 0.75s;
}
.la-atom > div:nth-child(2):before {
  -webkit-animation-delay: 0s, -1.125s;
  -moz-animation-delay: 0s, -1.125s;
  -o-animation-delay: 0s, -1.125s;
  animation-delay: 0s, -1.125s;
}
.la-atom > div:nth-child(3) {
  -webkit-transform: rotate(120deg);
  -moz-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  -o-transform: rotate(120deg);
  transform: rotate(120deg);
  -webkit-animation-delay: -0.25s;
  -moz-animation-delay: -0.25s;
  -o-animation-delay: -0.25s;
  animation-delay: -0.25s;
}
.la-atom > div:nth-child(3):before {
  -webkit-animation-delay: -1s, -0.75s;
  -moz-animation-delay: -1s, -0.75s;
  -o-animation-delay: -1s, -0.75s;
  animation-delay: -1s, -0.75s;
}
.la-atom > div:nth-child(4) {
  -webkit-transform: rotate(240deg);
  -moz-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  -o-transform: rotate(240deg);
  transform: rotate(240deg);
  -webkit-animation-delay: 0.25s;
  -moz-animation-delay: 0.25s;
  -o-animation-delay: 0.25s;
  animation-delay: 0.25s;
}
.la-atom > div:nth-child(4):before {
  -webkit-animation-delay: -0.5s, -0.125s;
  -moz-animation-delay: -0.5s, -0.125s;
  -o-animation-delay: -0.5s, -0.125s;
  animation-delay: -0.5s, -0.125s;
}
.la-atom.la-sm {
  width: 16px;
  height: 16px;
}
.la-atom.la-sm > div:not(:nth-child(1)):before {
  width: 4px;
  height: 4px;
  margin-top: -2px;
  margin-left: -2px;
}
.la-atom.la-2x {
  width: 64px;
  height: 64px;
}
.la-atom.la-2x > div:not(:nth-child(1)):before {
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
}
.la-atom.la-3x {
  width: 96px;
  height: 96px;
}
.la-atom.la-3x > div:not(:nth-child(1)):before {
  width: 30px;
  height: 30px;
  margin-top: -15px;
  margin-left: -15px;
}
/*
  * Animations
  */
@-webkit-keyframes ball-atom-position {
  50% {
    top: 100%;
    left: 100%;
  }
}
@-moz-keyframes ball-atom-position {
  50% {
    top: 100%;
    left: 100%;
  }
}
@-o-keyframes ball-atom-position {
  50% {
    top: 100%;
    left: 100%;
  }
}
@keyframes ball-atom-position {
  50% {
    top: 100%;
    left: 100%;
  }
}
@-webkit-keyframes ball-atom-size {
  50% {
    -webkit-transform: scale(0.5, 0.5);
    transform: scale(0.5, 0.5);
  }
}
@-moz-keyframes ball-atom-size {
  50% {
    -moz-transform: scale(0.5, 0.5);
    transform: scale(0.5, 0.5);
  }
}
@-o-keyframes ball-atom-size {
  50% {
    -o-transform: scale(0.5, 0.5);
    transform: scale(0.5, 0.5);
  }
}
@keyframes ball-atom-size {
  50% {
    -webkit-transform: scale(0.5, 0.5);
    -moz-transform: scale(0.5, 0.5);
    -o-transform: scale(0.5, 0.5);
    transform: scale(0.5, 0.5);
  }
}
@-webkit-keyframes ball-atom-zindex {
  50% {
    z-index: 10;
  }
}
@-moz-keyframes ball-atom-zindex {
  50% {
    z-index: 10;
  }
}
@-o-keyframes ball-atom-zindex {
  50% {
    z-index: 10;
  }
}
@keyframes ball-atom-zindex {
  50% {
    z-index: 10;
  }
}
@-webkit-keyframes ball-atom-shrink {
  50% {
    -webkit-transform: translate(-50%, -50%) scale(0.8, 0.8);
    transform: translate(-50%, -50%) scale(0.8, 0.8);
  }
}
@-moz-keyframes ball-atom-shrink {
  50% {
    -moz-transform: translate(-50%, -50%) scale(0.8, 0.8);
    transform: translate(-50%, -50%) scale(0.8, 0.8);
  }
}
@-o-keyframes ball-atom-shrink {
  50% {
    -o-transform: translate(-50%, -50%) scale(0.8, 0.8);
    transform: translate(-50%, -50%) scale(0.8, 0.8);
  }
}
@keyframes ball-atom-shrink {
  50% {
    -webkit-transform: translate(-50%, -50%) scale(0.8, 0.8);
    -moz-transform: translate(-50%, -50%) scale(0.8, 0.8);
    -o-transform: translate(-50%, -50%) scale(0.8, 0.8);
    transform: translate(-50%, -50%) scale(0.8, 0.8);
  }
}
