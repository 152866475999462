.navbar {
  padding: 2rem 4rem;
}

.navbar-item {
  outline: none;
}

.navbar-item:hover {
  text-decoration: underline #00ae41 4px;
}

.navbar-dropdown .navbar-item {
  color: #1f1f1f !important;
}

.navbar-logo {
  margin: auto !important;
  height: 1rem;
}
