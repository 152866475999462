.footer {
  background-color: #f3f7fe !important;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 180px;
}

.footer-logo {
  height: 1rem;
}

.footer-filler {
  height: 180px;
}
